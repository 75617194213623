import { hooks } from "./store";
import QR from "react-qr-code";
import { LoadingDNA } from ":src/components/loaders";
export default function QRCodeBar() {
  const $qr_subroute = hooks.useQRLink();
  return (
    <div className="flex flex-col w-full h-full items-center justify-between overflow-auto p-7">
      <div className="flex flex-col w-100% h-fit self-center   overflow-hidden">
        <img
          className="w-100%  object-cover self-center"
          src="/logo.png"
          alt="asd"
        />
      </div>
      <div className="relative flex w-full h-fit mt-10 items-center justify-center">
        {!$qr_subroute ? (
          <div className="relative flex flex-row items-center justify-center w-full h-full">
            <img
              className="w-full h-full object-cover self-center"
              src="/qr_placeholder.png"
              alt="wow"
            />
            <div className="absolute flex flex-col w-full h-full text-white items-center justify-center text-sm">
              <LoadingDNA wrapperStyle={{}} />
              <span>generating barcode</span>
            </div>
          </div>
        ) : (
          <QR fgColor="#fff" bgColor="#0D2937" value={$qr_subroute}  />
        )}
      </div>
      <footer className=" w-fit h-fit text-m font-bold text-white">
        Powered by Backyard
      </footer>
    </div>
  );
}
